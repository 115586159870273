import { Carousel } from '@mantine/carousel';
import { Card, Text  } from '@mantine/core';
import { useEffect, useState } from 'react';

const data = [
  {
    image: "media/Corridors.jpeg",
    title: "Corridors",
    description: "Corridors",
  },
  {
    image: "media/Restaurant1.jpeg",
    title: "",
    description: "Restaurant",
  },
  {
    image: "media/LongView2.jpeg",
    title: "",
    description: "Long View",
  },
  {
    image: "media/RiverNearBy.jpeg",
    title: "",
    description: "River Near By",
  },
  {
    image: "media/Exterior.jpeg",
    title: "",
    description: "Exterior",
  }
];

export function SmallCarousel() {
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setViewportWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const getSlideSize = () => {
    if (viewportWidth < 600) {
      return '100%';
    } else if (viewportWidth >= 600 && viewportWidth < 900) {
      return '50%';
    } else {
      return '33.333333%';
    }
  };

  const getSlidesToScroll = () => {
    if (viewportWidth < 600) {
      return 1;
    } else if (viewportWidth >= 600 && viewportWidth < 900) {
      return 2;
    } else {
      return 2;
    }
  };

  const carouselStyles = {
    control: {
      display: 'flex',
      justifyContent: 'center',
      alignItems:"center",
      marginTop:"-90px"
      // textAlign:"center"
      // alignContent:"center"
    },
    controlInactive: {
      opacity: 0,
      cursor: 'default',
    },
  };

  return (
    <Carousel
      withIndicators
      height={400}
      slideSize={getSlideSize()}
      slideGap={5}
      mx="auto"
      loop
      align="start"
      slidesToScroll={getSlidesToScroll()}
      style={{ marginLeft: "10%", marginRight: "10%" }}
      styles={carouselStyles}
    >
      {data.map((item, index) => (
        <Carousel.Slide key={index}>
          <Card shadow="sm" padding="md" style={{ textAlign: 'center',backgroundColor:"#f7ddea" }}>
            <img
              src={item.image}
              alt={item.description}
              style={{ width: '100%', height: '300px', objectFit: 'cover' }}
            />
            <Text weight={500} size="sm" style={{ marginTop: '8px' }}>
              {item.description} <br />
              {/* ___ <br />
              {item.description} */}
            </Text>
          </Card>
        </Carousel.Slide>
      ))}
    </Carousel>
  );
}