import { Content } from "../Home/Content";
import { SmallCarousel } from "../Home/SmallCarousel";
import { Rooms } from "./HomeRooms";
import { TextContent } from "../Text";
import { Hero } from "../Hero";
import { Video } from "./Video";

export function Home() {
  return (
    <div className="App">
        {/* <Demo /> */}
        {/* <Hero text="" url="media/Home_Video.mp4" /> */}
        <Video />
        <br />
        <TextContent text="About Us" />
        <Content />
        <TextContent text="Rooms" />
        <Rooms />
        <TextContent text="At The Hotel" />
        <br />
        <SmallCarousel />
    </div>
  );
}