import { Card, Text, Container, Grid, Col, Image } from "@mantine/core";

const menuItems = [
  {
    title: "WONDER VALLEY , ADVENTURE PARK ANACHAL",
    description:
      "Situated at a distance of 13 km from Munnar- the Kashmir of the south, Wonder valley Anachal is a place where you are welcomed by the refreshing breeze from the valley, recreate and rejoice yourself and feel the wonders hidden between woods and waters.",
    imageSrc: "media\\NearBy\\wonderwalley.jpg",
  },
  {
    title: "Martial Arts and Kathakali",
    description:
      "There are various centers  of exhibition for the arts which can be prebooked",
    imageSrc: "media\\NearBy\\kathakali.jpg",
  },

  {
    title: "Anakulam Elephant safari",
    description:
      "Distance : 0.5 km \n This is on of the very few places where you can observe wild elephants in it fully natural habitat. Ideal visit time would be in the evenings.An unforgettable experience indeed.",
    imageSrc: "media\\NearBy\\elephant.jpg",
  },
  {
    title: "Ayurveda and Wellness",
    description:
      "There are a number of ayurveda and wellness centre's in close proximity of our property which offers a variety of services.",
    imageSrc: "media\\NearBy\\ayurvedha.jpg",
  },
  {
    title: "Zip Line at Iruttukanam",
    description: "Distance :  1 KM",
    imageSrc: "media\\NearBy\\zipline.jpg",
  },
  {
    title: "Pettimudi Hill Top Trekking",
    description: "4 KM Trekking",
    imageSrc: "media\\NearBy\\trekking.jpg",
  },
  {
    title: "Kallar River & Kampiline Waterfalls",
    description: "Distance : 50 Mtrs",
    imageSrc: "media\\NearBy\\waterfall.jpg",
  },
  {
    title: "Rose Garden Visit",
    description: "Distance : 0.5 km",
    imageSrc: "media\\NearBy\\rosegarden.jpg",
  },
  {
    title: "Sengulam Lake and Boating",
    description: "Distance : 0.5 km",
    imageSrc: "media\\NearBy\\boating.jpg",
  },
  {
    title: "Spice farm walks with local farmers shopping",
    description: "Distance : 0.5 km",
    imageSrc: "media\\NearBy\\spicefarms.jpg",
  },
  {
    title: "Letchmi Hills Hiking",
    description: "Distance : 0.5 km",
    imageSrc: "media\\NearBy\\hillhiking.jpg",
  },
  {
    title: "Kerala Farm",
    description: "Distance : 0.5 km",
    imageSrc: "media\\NearBy\\keralafarm.jpg",
  },

 
];

export const Menu = () => {
  return (
    <div
      style={{
        backgroundColor: "#FBF5E9", // Add background color for the entire component here
        padding: "40px 0",
      }}
    >
      <Container
        size="md"
        style={{ backgroundColor: "white", paddingTop: "1%" }}
      >
        {/* <TextContent text={item.title} /> */}
        <br />
        <br />

        <Grid gutter="lg">
          {menuItems.map((item, index) => (
            <Col key={index} span={12} md={6}>
              <Card
                shadow="sm"
                padding="xl"
                component="a"
                href="https://www.youtube.com/watch?v=dQw4w9WgXcQ"
                target="_blank"
                style={{ flex: 1, margin: "10px" }}
              >
                <Card.Section>
                  <Image src={item.imageSrc} height={200} alt="No Image!" />
                </Card.Section>
                <Text weight={500} size="lg" mt="md">
                  {item.title}
                </Text>
                <Text mt="xs" color="dimmed" size="sm" h={80}>
                  {item.description}
                </Text>
              </Card>
            </Col>
          ))}
        </Grid>
        <br />
      </Container>
    </div>
  );
};
