import { useState } from "react";
import { Carousel } from "@mantine/carousel";
import { Image, Text, Button } from "@mantine/core";

type MediumCarouselProps = {
  images: {
    image: string;
    title: string;
    category: string;
  }[];
};



export function MediumCarousel({ images }: MediumCarouselProps) {
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);

  const slides = images.map((item, index) => (
    <Carousel.Slide
      key={item.title}
      onMouseEnter={() => setHoveredIndex(index)}
      onMouseLeave={() => setHoveredIndex(null)}
      style={{ height: "800px" 
    }}
       // Adjust the height of each slide here
    >
      <div
        style={{
          position: "relative",
          height: "876px",
          width: "1024px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Image
          src={item.image}
          alt={item.title}
          style={{
            flex: "1",
            objectFit: "cover",
            transition: "transform 0.2s ease", // Add a transition effect for smooth scaling
          }}
        />
        {hoveredIndex === index && (
          <div
            style={{
              position: "absolute",
              left: "50%",
              transform: "translate(-50%, -50%)",
              textAlign: "center",
              background: "transparent",
              color: "white",
              padding: "10px",
              borderRadius: "4px",
            }}
          >
            {/* <Text>{item.title}</Text> */}
            {/* <Button variant="outline" style={{ marginTop: "10px" }}>
              {item.category}
            </Button> */}
          </div>
        )}
      </div>
    </Carousel.Slide>
  ));

  return (
    <Carousel
      height={800}
      maw={1000} // Adjust the maximum width of the carousel here
      mx="auto"
      withIndicators
      style={{ overflow: "hidden", paddingTop: 50, paddingBottom: 50 }} // Hide any content that overflows the carousel
    >
      {slides}
    </Carousel>
  );
}
