import { Container, Text } from "@mantine/core";
interface contentProps {
  content: string;
}
export function Content({ content }: contentProps) {
  return (
    <Container
      size="sm"
      style={{ textAlign: "center", backgroundColor: "white" }}
    >
      {/* First Paragraph */}{" "}
      <Text />
      {/* Second Paragraph */}{" "}
      <Text
        size="md"
        style={{
          fontFamily: "Quicksand sans-serif",
          fontSize: 22,
          lightingColor: "red",
          font: "bolder",
          color: "black",
          lineHeight: 1.5,
          maxWidth: 600,
          margin: "0 auto",
          paddingBottom: 20,
        }}
      >
        {content}{" "}
      </Text>
      {/* Third Paragraph */}{" "}
      
    </Container>
  );
}
