import { Hero } from "../Hero";
import { TextContent } from "../Text";
import { Content } from "../Content";
import { Menu } from "./Menu";

const content = "Zip line at iruttukanam 1.5 Km from hotel, Pettimudi hill top trekking, Kallar River, Elephant safari, Kerala farm spices shop, Ayurvedic massage centres, Hot air balloon at aanachaal, Wonder valley adventure park aanachaal"

  
export function NearBy() {
  return (
    <div className="App">
        <Hero text="Near By" url="media/NearBy/NearBy.gif" />
        <TextContent text="Near By" />
        <Content content={content} />
        {/* <Cards /> */}

        <Menu />
    </div>
  );
}