import { Container, Text } from "@mantine/core";

export function Content() {
  return (
    <Container
      size="sm"
      style={{ textAlign: "center", backgroundColor: "white" }}
    >
      {/* First Paragraph */} <Text />
      {/* Second Paragraph */}{" "}
      <Text
        size="md"
        style={{
          fontFamily: "Quicksand sans-serif",
          fontSize: 22,
          textAlign: "justify",
          lightingColor: "red",
          font: "bolder",
          color: "black",
          lineHeight: 2,
          maxWidth: 600,
          margin: "0 auto",
          paddingBottom: 20,
        }}
      >
        PURNA RIVERA is a 3 star botique hotel located on the N H 49- Kochi - Munnar-
        Madurai highway having 14 rooms and multi-cuisine restaurant. There are
        11 Classic Rooms and 3 Superior rooms. {" "}
      </Text>
      {/* Third Paragraph */}{" "}
      <Text
        size="md"
        style={{
          fontFamily: "Quicksand sans-serif",
          textAlign: "justify",
          fontSize: 18,
          maxWidth: 700,
          margin: "0 auto",
          paddingBottom: 40,
        }}
      >
        The promoters of Purna Rivera is ace and grace engineering and
        hospitality pvt ltd, which is into the fields of Engineering,
        Construction and Real Estate. Our motto at Purna Rivera is to offer
        value for money hospitality experience to our guests by providing
        quality amenities and prompt services at affordable prices. You are
        assured of well- equipped rooms with spring mattress and white cotton
        linen ,clean and hygienic bathing experience with 24 hours running hot
        water, spacious wardrobes and a team of young and energetic staff
        members at your service round the clock…{" "}
      </Text>{" "}
    </Container>
  );
}
