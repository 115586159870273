import { Tabs, TabsProps, rem, Center, Grid, Image } from "@mantine/core";
import { TextContent } from "../Text";

const two_bedroom_images = [
  {
    url: "media/HouseBoat/2_bed_room_boat_1.jpeg",
  },
  {
    url: "media/HouseBoat/2_bed_room_boat_2.jpeg",
  },
  {
    url: "media/HouseBoat/2_bed_room_boat_3.jpeg",
  },
  {
    url: "media/HouseBoat/2_bed_room_boat_4.jpeg",
  },
];

const three_bedroom_images = [
  {
    url: "media/HouseBoat/3_bed_room_boat_1.jpeg",
  },
  {
    url: "media/HouseBoat/3_bed_room_boat_2.jpeg",
  },
  {
    url: "media/HouseBoat/3_bed_room_boat_3.jpeg",
  },
  {
    url: "media/HouseBoat/3_bed_room_boat_4.jpeg",
  },
  {
    url: "media/HouseBoat/3_bed_room_boat_5.jpeg",
  },
];

const four_bedroom_images = [
  {
    url: "media/HouseBoat/4_bed_room_boat_1.jpeg",
  },
  {
    url: "media/HouseBoat/4_bed_room_boat_2.jpeg",
  },
  {
    url: "media/HouseBoat/4_bed_room_boat_3.jpeg",
  },
  {
    url: "media/HouseBoat/4_bed_room_boat_4.jpeg",
  },
];
export function HouseBoatImages() {
  return (
    <div>
      <TextContent text="2 Bed Room Boat" />
      <p></p>
      <p></p>
      <p></p>
      <Grid
        gutter="md"
        style={{
          marginLeft: "10%",
          marginRight: "10%",
          marginTop: "1%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {two_bedroom_images.map((image, index) => (
          <div key={index} style={{ padding: "10px" }}>
            <Image src={image.url} alt={`Image ${index + 1}`} />
          </div>
        ))}
      </Grid>

      <TextContent text="3 Bed Room Boat" />
      <p></p>
      <p></p>
      <p></p>
      <Grid
        gutter="md"
        style={{
          marginLeft: "10%",
          marginRight: "10%",
          marginTop: "1%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {three_bedroom_images.map((image, index) => (
          <div key={index} style={{ padding: "10px" }}>
            <Image src={image.url} alt={`Image ${index + 1}`} />
          </div>
        ))}
      </Grid>

      <TextContent text="4 Bed Room Boat" />
      <p></p>
      <p></p>
      <p></p>
      <Grid
        gutter="md"
        style={{
          marginLeft: "10%",
          marginRight: "10%",
          marginTop: "1%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {four_bedroom_images.map((image, index) => (
          <div key={index} style={{ padding: "10px" }}>
            <Image src={image.url} alt={`Image ${index + 1}`} />
          </div>
        ))}
      </Grid>
    </div>
  );
}
