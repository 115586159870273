import { Text } from "@mantine/core";

interface TextProps {
  text: string;
}

export function TextContent({ text }: TextProps) {
  return (
    <Text
      size="xl"
      style={{
        fontFamily: "Quicksand sans-serif",
        fontSize: 32,
        color: "#e8a307",
        textAlign: "center",
        fontWeight: "bold",
        lineHeight: 1, // Adjust the line height (smaller values reduce the gap)
        marginBottom: 5, // Adjust the margin between the text and underline
      }}
    >
      <span style={{ fontWeight: "bold", fontSize: "40px" }}>{text.charAt(0)}</span>
      {text.slice(1)}
      <div style={{marginTop:"-20px"}}>
        _ ______
      </div>
    </Text>
  );
}