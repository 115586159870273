import { Card, Image, Text, Grid } from "@mantine/core";
import { IconCheck } from "@tabler/icons-react";
import { TextContent } from "../Text";

const list1 = ["350 Square Feet Room", "Twin Spring Beds", "Tea/Coffe Maker", "Balcony","LED TV", "Hot Water", "Spacious Wardrobe", "Space to accomodate 2 more extra beds"];
const list2 = ["LED TV", "Hot Water", "Spacious Wardrobe","250 Square Feet Room", "Queen Size Spring Bed", "Tea/Coffe Maker"];
export function RoomCard() {
  return (
    <div>
      <Grid>
        <Grid.Col span={6}>
          <Card
            shadow="sm"
            padding="xl"
            component="a"
            href="https://www.youtube.com/watch?v=dQw4w9WgXcQ"
            target="_blank"
            style={{ flex: 1, margin: "10px" }}
          >
            <Card.Section>
              <Image
                src="media/Rooms/ClassicRoom.jpeg"
                
                alt="No way!"
              />
            </Card.Section>
          </Card>
        </Grid.Col>
        <Grid.Col span={6}>
          <Text weight={500} size="lg" mt="md">
            Amenities
          </Text>

          <Text mt="xs" color="dimmed" size="sm">
    
                  <ul>
                    {list2.map((item, index) => (
                      <p>
                        <IconCheck size={18} style={{ marginRight: 8 }} />
                        {item}
                      </p>
                    ))}
                  </ul>
          </Text>
        </Grid.Col>
      </Grid>

      <TextContent text="Superior Room" />
      <p></p>
      <p></p>

      <Grid>
        <Grid.Col span={6}>
          <Card
            shadow="sm"
            padding="xl"
            component="a"
            href="https://www.youtube.com/watch?v=dQw4w9WgXcQ"
            target="_blank"
            style={{ flex: 1, margin: "10px" }}
          >
            <Card.Section>
              <Image
                src="media/Rooms/SuperiorRoom.jpeg"
                
                alt="No way!"
              />
            </Card.Section>
          </Card>
        </Grid.Col>
        <Grid.Col span={6}>
          <Text weight={500} size="lg" mt="md">
            Amenities
          </Text>

          <Text mt="xs" color="dimmed" size="sm">
    
                  <ul>
                    {list1.map((item, index) => (
                      <p>
                        <IconCheck size={18} style={{ marginRight: 8 }} />
                        {item}
                      </p>
                    ))}
                  </ul>
          </Text>
        </Grid.Col>
      </Grid>

      <p></p>
      <p></p>
      <p></p>
      <p></p>
      <p></p>
      <p></p>

    </div>
  );
}
