import React from 'react'
import { Image } from "@mantine/core";

const front_page = "media/RestaurantMenu/front_page.jpeg"
const inner_page = "media/RestaurantMenu/Inner_page_2.jpeg"
const inner_page_2 = "media/RestaurantMenu/coverpage.jpeg"
const front_page_2 = "media/RestaurantMenu/Inner_page_3.jpeg"


export function MenuImage() {
  return (
    <div>
        <Image src={front_page}  />
        <Image src={inner_page_2}  />
        <Image src={inner_page}  />
        <Image src={front_page_2}  />
    </div>
  )
}
