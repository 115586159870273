import { MediumCarousel } from "../MediumCarousel";

const images = [
    {
      image: "media/Rooms/ClassicRoom.jpeg",
      title: "Loading Image",
      category: "Classic",
    },
    {
      image: "media/Rooms/SuperiorRoom.jpeg",
      title: "Loading Image",
      category: "Superior",
    },

  
    
  ];

export function Rooms() {
    return (
      <div className="App">
          
          <MediumCarousel images={images} />
          
      </div>
    );
  }