import React from 'react'
import { Hero } from '../Hero'
import { Images } from './Images';
import { Content } from './Content';

export function Gallery() {
    return (
      <div className="App">
          <Hero text="Gallery" url="media/Gallery/Gallery.webp" />
          <Images />
          <p></p>
          <p></p>
          <p></p>
          {/* <Content /> */}
          
          
      </div>
    );
  }
