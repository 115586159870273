import { HeaderMenuColored } from "./components/Header";
import { Footer } from "./components/Footer";
import { Home } from "./components/Home/Home";
import { Routes, Route } from "react-router-dom";
import { Classic } from "./components/Rooms/Classic";
import { Gallery } from "./components/Gallery/Gallery";
import { Contact } from "./components/Contact/Contact";
import { NearBy } from "./components/NearBy/NearBy";
import { Floating } from "./components/Floating";
import { Superior } from "./components/Rooms/Superior";
import { HouseBoatService } from "./components/HouseBoatService/HouseBoatService";
import { RestaurantMenu } from "./components/RestaurantMenu/RestaurantMenu";
import { Rooms } from "./components/Rooms/Rooms";
// Make sure to provide the correct path to Header.tsx

const App = () => {
  // You can define your mainLinks and userLinks arrays here
  const links = [
    { link: "/", label: "Home" },
    { link: "/restaurantmenu", label: "Restaurant Menu" },
    { link: "/houseboatservice", label: "House Boat Service" },
    { link: "/rooms", label: "Rooms" },
    { link: "/gallery", label: "Gallery" },
    { link: "/nearby", label: "Nearby" },
  ];

  const footerData = [
    {
      title: "Links Group 1",
      links: [
        { link: "/rooms", label: "Rooms" },
        { link: "/restaurantmenu", label: "Restaurant Menu" },
        // Add more links here...
      ],
    },
    {
      title: "Links Group 2",
      links: [
        { link: "/gallery", label: "Gallery" },
        { link: "/nearby", label: "Nearby" },
        { link: "/contact", label: "Contact" },
        // Add more links here...
      ],
    },
  ];

  return (
    <div className="App">
      <HeaderMenuColored links={links} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/houseboatservice" element={<HouseBoatService />} />
        <Route path="/restaurantmenu" element={<RestaurantMenu />} />
        <Route path="/rooms" element={<Rooms />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/nearby" element={<NearBy />} />

        {/* <Route path="/contact" element={<Contact />} /> */}
      </Routes>
      <Floating />
      <Footer data={footerData} />
    </div>
  );
};

export default App;
