export function Floating() {
  return (
    <div className="App">
      <div style={{ position: "fixed", bottom: "20px", right: "20px" }}>
        <div
          style={{
            width: "50px",
            height: "50px",
            borderRadius: "50%",
            overflow: "hidden",
            position: "relative",
            cursor: "pointer",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "-10%",
              left: "-10%",
              width: "120%",
              height: "120%",
              borderRadius: "50%",
              background:
                "radial-gradient(transparent, transparent 30%, rgba(255, 255, 255, 0.3) 60%, transparent 100%)",
              animation: "moveInOut 6s linear infinite", // Slower movement
              cursor: "pointer",
            }}
            
          />
          <a
            href={`https://wa.me/+918078233444`}
            target="_blank"
            rel="noopener noreferrer"
          >
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/6b/WhatsApp.svg/1200px-WhatsApp.svg.png"
            alt="Button"
            style={{
              width: "60px",
              height: "60px",
              objectFit: "cover",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: 1,
            }}
          />
          </a>
          <div
            style={{
              content: "",
              position: "absolute",
              display: "block",
              width: "300%",
              height: "300%",
              boxSizing: "border-box",
              marginLeft: "-100%",
              marginTop: "-100%",
              borderRadius: "45px",
              backgroundColor: "#0d6f45",
              WebkitAnimation:
                "pulse-ring 1.5s cubic-bezier(0.215, 0.61, 0.355, 1) infinite", // Slower movement
              animation:
                "pulse-ring 1.5s cubic-bezier(0.215, 0.61, 0.355, 1) infinite", // Slower movement
              zIndex: -1,
            }}
          />
        </div>
      </div>

      <style>
        {`
  @keyframes moveInOut {
    0%, 100% {
      transform: scale(0.8);
      opacity: 1;
    }
    50% {
      transform: scale(1.2);
      opacity: 0.5;
    }
  }

  @keyframes pulse-ring {
    0% {
      transform: scale(0.33);
      opacity: 1;
    }
    80%, 100% {
      opacity: 0;
    }
  }
  `}
      </style>
    </div>
  );
}
