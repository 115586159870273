import React from "react";
import { Amenities } from "../Home/Amenities";
import { Hero } from "../Hero";
import { MediumCarousel } from "../MediumCarousel";
import { TextContent } from "../Text";
import { Button, Card, Center, Image } from "@mantine/core";
import { Cards } from "../NearBy/Card";
import { RoomCard } from "./RoomCard";

// Make sure to provide the correct path to Header.tsx
const images = [
  {
    image: "media/Rooms/ClassicRoom2.jpeg",
    title: "",
    category: "",
  },
  {
    image: "media/Rooms/ClassicRoom3.jpeg",
    title: "",
    category: "",
  },
];

const list1 = [
  "250 Square Feet Room",
  "Queen Size Spring Bed",
  "Tea/Coffe Maker",
];
const list2 = ["LED TV", "Hot Water", "Spacious Wardrobe"];
export const Rooms = () => {
  // You can define your mainLinks and userLinks arrays here
  return (
    <div className="App">
      <Hero text="Rooms" url="media/Rooms/ClassicRoom.jpeg" />
      <TextContent text="Classic Room" />
      <p></p>
      <p></p>
      <RoomCard />
    </div>
  );
};
