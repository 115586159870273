import React from "react";
import { AspectRatio, Image } from "@mantine/core";
export function Video() {
  return (
    <div>
      <video className='videoTag' autoPlay loop muted style={{ width: "100%"}}>
        <source src='media/Home_Video.mp4'	 type='video/mp4' />
    </video>
    </div>
  );
}
