import React from 'react'
import { Hero } from '../Hero';
import { TextContent } from '../Text';
import { HouseBoatImages } from './HouseBoatImages';

export function HouseBoatService() {
    return (
      <div>
        <Hero text="House Boat Service" url="media/HouseBoat/2_bed_room_boat_3.jpeg" />
        
        <HouseBoatImages />
      </div>
    );
  }