import React from 'react'
import { Hero } from '../Hero';
import { TextContent } from '../Text';
import { MenuImage } from './MenuImage';

export function RestaurantMenu() {
    return (
      <div>
        <Hero text="Restaurant Menu" url="media/Restaurant3.jpeg " />
        <p></p>
        <p></p>
        <TextContent text="Restaurant Menu" />
        <p></p>
        <p></p>
        <MenuImage />
      </div>
    );
  }