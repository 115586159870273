import { Tabs, TabsProps, rem, Center, Grid, Image } from "@mantine/core";
import {
  IconPhoto,
} from "@tabler/icons-react";
import { useState } from "react";

const images = [
  {
    category: "Corridor",
    url: "media/Corridors.jpeg",
  },
  {
    category: "LongView",
    url: "media/LongView2.jpeg",
  },
  {
    category: "LongView",
    url: "media/LongView3.jpeg",
  },
  {
    category: "LongView",
    url: "media/LongView4.jpeg",
  },
  {
    category: "LongView",
    url: "media/LongView5.jpeg",
  },
  {
    category: "LongView",
    url: "media/LongView6.jpeg",
  },
  {
    category: "LongView",
    url: "media/LongView7.jpeg",
  },
  {
    category: "LongView",
    url: "media/LongView8.jpeg",
  },
  {
    category: "LongView",
    url: "media/LongView9.jpeg",
  },
  {
    category: "LongView",
    url: "media/LongView10.jpeg",
  },
  {
    category: "LongView",
    url: "media/LongView11.jpeg",
  },
  {
    category: "Restaurant",
    url: "media/Restaurant1.jpeg",
  },
  {
    category: "Restaurant",
    url: "media/Restaurant2.jpeg",
  },
  {
    category: "Restaurant",
    url: "media/Restaurant3.jpeg",
  },
  {
    category: "River",
    url: "media/RiverNearBy.jpeg",
  },
  
  // Add more images with category "Spa"
  // Repeat for other categories: "Rooms", "Restaurants", "OutDoor", "Lobby"
];

function StyledTabs(props: TabsProps) {
  return (
    <Center style={{ width: "100%", marginTop: "1%" }}>
      <Tabs
        unstyled
        styles={(theme) => ({
          root: {
            display: "flex",
            justifyContent: "center",
          },
          tab: {
            ...theme.fn.focusStyles(),
            backgroundColor:
              theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.white,
            color:
              theme.colorScheme === "dark"
                ? theme.colors.dark[0]
                : theme.colors.gray[9],
            border: `${rem(1)} solid ${
              theme.colorScheme === "dark"
                ? theme.colors.dark[6]
                : theme.colors.gray[4]
            }`,
            padding: `${theme.spacing.xs} ${theme.spacing.md}`,
            cursor: "pointer",
            fontSize: theme.fontSizes.sm,
            display: "flex",
            alignItems: "center",

            "&:disabled": {
              opacity: 0.5,
              cursor: "not-allowed",
            },

            "&:not(:first-of-type)": {
              borderLeft: 0,
            },

            "&:first-of-type": {
              borderTopLeftRadius: theme.radius.md,
              borderBottomLeftRadius: theme.radius.md,
            },

            "&:last-of-type": {
              borderTopRightRadius: theme.radius.md,
              borderBottomRightRadius: theme.radius.md,
            },

            "&[data-active]": {
              backgroundColor: theme.colors.blue[7],
              borderColor: theme.colors.blue[7],
              color: theme.white,
            },
          },

          tabIcon: {
            marginRight: theme.spacing.xs,
            display: "flex",
            alignItems: "center",
            "@media (max-width: 600px)": {
              display: "none"
            }
          },

          tabsList: {
            display: "flex",
            flexDirection: "row", // Default to row layout

            // Additional styles for mobile (below 600px)
            "@media (max-width: 600px)": {
              flexDirection: "column", // Switch to column layout
              alignItems: "center",
              // Ensure equal width for buttons
              "& > *": {
                width: "100%",
                textAlign: "center",
                justifyContent: "center",
              },
            },

            "@media (min-width: 600px) and (max-width: 900px)": {
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            },
          },
        })}
        {...props}
      />
    </Center>
  );
}

export function Images() {
  const [selectedTab, setSelectedTab] = useState("All"); // Set initial state to "All"
  const filteredImages =
    selectedTab === "All"
      ? images
      : images.filter((image) => image.category === selectedTab);
  return (
    <div className="App">
      <StyledTabs>
        <Tabs.List>
          <Tabs.Tab
            value="All"
            icon={<IconPhoto size="1rem" />}
            onClick={() => setSelectedTab("All")}
            style={{
              backgroundColor: selectedTab === "All" ? "#1c7ed6" : undefined,
            }}
          >
            All
          </Tabs.Tab>
          <Tabs.Tab
            value="LongView"
            icon={<IconPhoto size="1rem" />}
            onClick={() => setSelectedTab("LongView")}
          >
            Long View
          </Tabs.Tab>
          <Tabs.Tab
            value="Restaurant"
            icon={<IconPhoto size="1rem" />}
            onClick={() => setSelectedTab("Restaurant")}
          >
            Restaurant
          </Tabs.Tab>
          <Tabs.Tab
            value="Corridor"
            icon={<IconPhoto size="1rem" />}
            onClick={() => setSelectedTab("Corridor")}
          >
            Corridor
          </Tabs.Tab>
          <Tabs.Tab
            value="River"
            icon={<IconPhoto size="1rem" />}
            onClick={() => setSelectedTab("River")}
          >
            River Near By
          </Tabs.Tab>
          <Tabs.Tab
            value="Exterior"
            icon={<IconPhoto size="1rem" />}
            onClick={() => setSelectedTab("Exterior")}
          >
            Exterior
          </Tabs.Tab>
        </Tabs.List>
      </StyledTabs>
      <Grid
        gutter="md"
        style={{
          marginLeft: "10%",
          marginRight: "10%",
          marginTop: "1%",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        {filteredImages.map((image, index) => (
          <div key={index} style={{ padding: "10px" }}>
            <Image src={image.url} alt={`Image ${index + 1}`} height={220} />
          </div>
        ))}
      </Grid>
    </div>
  );
}
